@import "../../App.css";

.footer {
    height: 155px;
    background-color: var(--navbarMain);
    overflow: hidden;
}

.icons {
    font-size: 30px;
    color: var(--whiteMain);
    justify-content: center;
    padding: 30px;
    grid-gap: 35px;
    height: 110px;
    display: grid;
    grid-template-columns: repeat(4, auto);
    list-style: none;
    text-align: center;
}

.footer-icon {
    text-decoration: none;
    border-radius: 50px;
    padding: 0.4rem 0.65rem;
}

.footer-icon:hover {
    background-color: var(--navbarHover);
    transition: all 0.2s ease-out;
}

.copyright {
    color: var(--whiteMain);
    font-size: 12px;
    text-align: center;
}