.App {
    font-family: 'Roboto', sans-serif;
}

/* For global colour theme */
:root {
    --navbarMain: rgb(39,54,59);
    --navbarHover: rgb(57, 77, 85);

    --tealMain: rgb(113,178,173);
    --tealDark: rgb(114,173,160);
    --tealDarker: rgb(65,94,94);
 
    --whiteMain: rgb(245,245,245);
    --whiteDark: rgb(221,221,221);
    --whiteDarker: rgba(199,199,199);
}

/* overflow debugger */
/* * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  } */