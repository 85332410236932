@import "../App.css";

.about-page-container {
    min-height: calc(100vh - 80px + 161px);
    background-color: var(--whiteDark);
}

.about-landing {
    min-height: calc(100vh - 80px);
    height: 90vh;
    background: var(--tealMain);
    overflow-x: hidden;
}

.headshot-container {
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.headshot {
    max-height: 55vh;
    max-width: 80vw;
    padding: 7px;
    margin: 40px;
    background-color: var(--navbarHover) !important;
}

.intro-container {
    padding: 0 10vw;
    position: relative;
    flex-direction: column;
}

.heading {
    margin: 5px;
    font-size: 3.5em !important;
    color: var(--whiteMain);
}

.text {
    margin: 5px;
    font-size: 1.5rem;
    color: var(--navbarHover);
}

/* WAVY DIV */
.wavy-div {
    position: relative;
}
.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 161px;
}

.wave .shape-fill {
    fill: #71B2AD;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
    .wave svg {
        width: calc(100% + 1.3px);
        height: 158px;
    }
}
/* END OF WAVY DIV */

.about-info {
    padding: 25vh 10vw 10vh 10vw;
    color: var(--navbarHover);
}

.heading-interest {
    font-size: 3rem !important;
    padding-top: 1rem;
}

.interests-container {
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    width: 100%;
}

.interest {
    justify-content: center;
    margin: 7vh 0;
    width: 100%;
    flex-wrap: wrap;
    /* overflow-x: hidden; */
}

.interest-text-container {
    margin-left: 5vw;
    width: 100%;
    min-width: 450px;

    /* GET THIS CENTERED? */
    align-items: center;
}

.carousel {
    min-width: 450px;
    max-width: 40%;
    margin-bottom: 5vh;
}

.carousel, .interest-text-container {
    flex: 1; 
    max-width: 50%;
}

.interest-heading {
    font-size: 2rem !important;;
    padding-bottom: 4vh;
}
