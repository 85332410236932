@import "../App.css";

.background {
    background: var(--tealMain);
    overflow: hidden;
}

.projects {
    min-height: calc(100vh - 80px);
    padding: 50px;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    font-family: "Roboto";
    font-weight: 400;
}

.headers {
    font-weight: 700;
    font-size: 25px;
}

.card {
    background-color: var(--whiteMain) !important;
    color: var(--whiteMain);
    /* Ensures cards do not stretch vertically to fill height: */
    align-self: center;
    transition: all .2s ease-in-out;
    min-height: 440px;
}

.card:hover {
    transform: scale(1.05);
}

.gh-icons {
    font-size: 30px;
    margin: auto;
    border-radius: 50px;
    color: var(--navbarMain);
}

.gh-icons:hover {
    background-color: var(--whiteDark);
    /* border-radius: 5px; */
    transition: all .05s ease-in-out;
    padding: 0 8px;
}