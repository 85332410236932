@import "../App.css";

.page-container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home-container {
    padding: 20vh 12vw 15vh 10vw;
    align-items: center;
}

.home {
    min-height: calc(100vh - 80px);
    background-color: var(--tealMain);
    overflow: hidden;
}

.hi-container {
    margin-bottom: 4rem;
}

.hi {
    color: var(--whiteMain);
}

.intro {
    color: var(--whiteMain);
    margin-bottom: 0.1rem;
}

.liu{
    color: var(--navbarHover) !important;
}

.typewriter {
    font-size: 30px;
    margin-top: 1rem;
    font-family: "Coolvetica"; 
    font-weight: 600;
    color: var(--navbarHover);
}

.cursorSize {
    color: var(--tealMain);
}

.memoji-container {
    padding: 17vh 10vw 15vh 10vw;
}
